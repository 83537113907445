import Vue from 'vue'
// 高德地图
import VueAMap from 'vue-amap'

Vue.use(VueAMap)

//const key = '7d7584d8705bc9c3779b9c5dcddf7406'
const key = 'b5f28a045617f7a5d8e7cd14ba3ada48'

const plugin = [
  'AMap.Autocomplete',
  'AMap.PlaceSearch',
  'AMap.Scale',
  'AMap.OverView',
  'AMap.ToolBar',
  'AMap.MapType',
  'AMap.PolyEditor',
  'AMap.CircleEditor',
  'AMap.MouseTool',
  'AMap.DistrictSearch',
  'AMap.MarkerClusterer'
]
const version = '1.4.15'

VueAMap.initAMapApiLoader({
  key: key,
  plugin: plugin,
  v: version,
})

window._AMapSecurityConfig = {
  securityJsCode: 'ed75090985b5cfa665d06f334770e473'
}